<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          v-show="index!==4 || isAssign"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        > 
          <div>
            <el-badge :value="backlog" class="item" v-if="nav.name === '待办管理' && backlog != '0'">
              <i v-if="nav.name === '待办管理'" class="icon icon-await"></i>
            </el-badge>
            <i v-if="nav.name === '待办管理' && backlog == '0'" class="icon icon-await"></i>
            <i v-if="nav.name === '办文管理'" class="icon icon-processing"></i>
            <i v-if="nav.name === '拟文管理'" class="icon icon-draft"></i>
            <i v-if="nav.name === '全部公文'" class="icon icon-all"></i>
            <i v-if="nav.name === '指派管理'" class="icon icon-zp"></i>
            <span>{{nav.name}}</span>
           </div>
        </router-link>
      </div>

      <div class="nf-tabs-content">
    <div class="nf-tabs-filter-form">
      <el-form :inline="true" :model="formInline" class="filter-form">
        <el-form-item class="form-row-item" label="收文日期">
          <el-date-picker
            class="form-row-date"
            v-model="formInline.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="紧急程度">
          <el-select class="form-row" v-model="formInline.pressing" placeholder="紧急程度">
            <el-option label="紧急" value="2"></el-option>
            <el-option label="急" value="1"></el-option>
            <el-option label="一般" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模糊搜索">
          <el-input class="form-row" v-model="formInline.keyword" placeholder="模糊搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="nf-tabs-content">
      <div class="nf-tabs-cont">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column header-align="center" label="公文标题">
            <template slot-scope="scope">
              <span class="nf-tag nf-tag-jinji" v-if="scope.row.emergencyLevel == '1' || scope.row.emergencyLevel == '2'">{{scope.row.emergencyLevel == '1' ? '急' : '紧急'}}</span>
              <span
                @click="toDetails(scope.row.id, scope.row.documentType, scope.row.documentArea, scope.row.documentStatus)"
                class="table-cont-title"
              >{{ scope.row.documentTitle }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="draftUnit" label="单位处室/人" width="200"></el-table-column>
          <el-table-column align="center" prop="receiveTime" label="收文时间" width="140"></el-table-column>
        </el-table>
      </div>
      <pagination
        v-if="page.total > param.pageSize"
        @pageChange="pageChange"
        :total="page.total"
        :currentPage="page.currentPage"
        :pageSize="page.pageSize"
      ></pagination>
    </div>

  </div> 
    </div>
    <el-button type="text" @click="isDialog = true"></el-button>
    <el-dialog title="删除原因" :visible.sync="isDialog" width="30%">
      <el-input
        type="textarea"
        :rows="3"
        v-model="reason_text"
       >
      </el-input>
      <div slot="footer" class="dialog-footer text_center">
        <el-button type="primary" @click="isDialog = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";
import pagination from '@/component/pagination/index.vue';
// import noData from '@/component/noData/index';
import TabNav from './component/tab-nav';
// import list from "./mock/index";

export default {
  components: {
    pagination,
    // noData,
    TabNav,
    nfBreadcrumb
  },
  data() {
    return {
      activeNav: 0,
      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "移动办公"
        }
      ],
      backlog:'',
      formInline: {
        date: '',
        type: '',
        pressing: '',
        keyword: ''
      },
      tabsNav: [
        {
          name: "待办管理",
          url: "awaitDocumentText"
        },
        {
          name: "办文管理",
          url: "documentText"
        },
        {
          name: "拟文管理",
          url: "proposedText"
        },
        {
          name: "全部公文",
          url: "allOfficial"
        },
        {
          name: "指派管理",
          url: "assign"
        }
      ],
      currentTabsNav: 0,
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10
      },
      param: {
        circulateType: 2,
        documentStatus: 2,
        emergencyLevel: '',
        endDate: '',
        keyWord: '',
        pageNum: 1,
        pageSize: 10,
        startDate: ''
      },
      tableData: [],
      reason_text: '', // 删除原因 
      isDialog: false, // 删除弹框

      param: {
        circulateType: 2,
        documentStatus: 2,
        emergencyLevel: '',
        endDate: '',
        keyWord: '',
        pageNum: 1,
        pageSize: 10,
        startDate: ''
      },
      isAssign: false
    };
  },
  created() {
    this.queryReceiptList();
    this.getIsAdmin();
    this.getBacklog()
  },
  methods: {
    async getBacklog() {
      const api = this.$fetchApi.queryReceiptList.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.backlog = data.result.total;
        this.$store.commit('render', this.backlog);
      }
    },
    async getIsAdmin () {
      const api = this.$fetchApi.isAdministrators.api;
      const data = await this.$fetchData.fetchGet({}, api, 'json');
      if (data.code == '200') {
        this.isAssign =   data.result;
      }
    },
    async queryReceiptList() {
      const api = this.$fetchApi.queryReceiptList.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.tableData = data.result.rows;
        this.page.total = data.result.total;
        this.backlog = data.result.total;
      }
    },
    pageChange(item) {
      this.param.pageNum = item.page_index;
      this.param.pageSize = item.page_limit;
      this.queryReceiptList(); //改变页码，重新渲染页面
    },

    onSubmit() {
      this.param.keyWord = this.formInline.keyword;
      this.param.emergencyLevel = this.formInline.pressing;
      if (this.formInline.date) {
        const start = new Date(this.formInline.date[0]);
        this.param.startDate = start.getFullYear() + '-' +
          (start.getMonth() + 1 >= 10
            ? start.getMonth() + 1
            : '0' + (start.getMonth() + 1)) + '-' +
          (start.getDate() >= 10 ? start.getDate() : '0' + start.getDate());
      
        const end = new Date(this.formInline.date[1]);
        this.param.endDate = end.getFullYear() + '-' +
        (end.getMonth() + 1 >= 10 ? end.getMonth() + 1
          : '0' + (end.getMonth() + 1)) + '-' +
        (end.getDate() >= 10 ? end.getDate() : '0' + end.getDate());
      } else {
        this.param.startDate = '';
        this.param.endDate = '';
      }
      this.queryReceiptList();
    },

    switchNav(data) {
      this.currentTabsNav = data;
    },

    async toDetails(id, code, area, status) {
      if (status === '5') {
        const params = {
          documentType: code,
          id: id
        };
        const api = this.$fetchApi.findApplyDetails.api;
        const data = await this.$fetchData.fetchPost(params, api, 'json');
        this.isDialog = true
        this.reason_text = data.result.documentDetail.deleteReason || '无'
        return 
      }
      id = id || 123;
      this.$router.push(
        '/formDetails?id=' +
          id +
          '&code=' +
          code +
          '&area=' +
          area +
          '&type=' +
          'handle'
      );
    },
  }
};
</script>

<style lang="less" scoped>
.tab-content {
  max-width: 1200px;
  margin: 0 auto;
}

.breadcrumb {
  border: none;
  margin-bottom: 30px;
}

.form-row {
  width: 105px;

  &-date {
    width: 250px;
  }
}

.filter-form {
  padding: 25px 0 0 20px;
}

.el-form-item {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

.nf-tag {
  display: inline-block;
  padding: 4px 8px;
  line-height: 11px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 10px;

  &-jinji {
    background: #f56c6c;
  }

  &-ji {
    background: #e6a23c;
  }

  &-yiban {
    background: #909399;
  }
}

.table-cont-title {
  margin-left: 10px;
  cursor: pointer;
}

.text_center {
  text-align: center;
}

.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_db.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }

    &-draft {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }
    &-all {
      background-image: url("../../assets/images/officialDocument/icon_all.png");
    }
    &-zp {
      background-image: url("../../assets/images/officialDocument/icon_zp.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
